import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from 'src/app/core/interfaces/enums';
import { UserData } from 'src/app/core/interfaces/localStorage';
import { Api } from '../api.service';
import { LocalStorageService } from '../../localStorage.service';

@Injectable({
	providedIn: 'root',
})
export class TokenService {
	constructor(
		private apiService: Api,
		private localStorageService: LocalStorageService
	) {}

	public receive(): Observable<{
		success: boolean
		token: string
		user:number
	}> {
		return this.apiService.sendGetRequest('guest/token').pipe(
			map((response) => {
				return response as { success: boolean; token: string; user: number; };
			}),
		);
	}

	public refresh(): Observable<any> {
		return this.apiService.sendPostRequest('token/refresh').pipe(
			map(async (token: any) => {
				const userParams =
					await this.localStorageService.getItemLocalStorage<UserData>(
						User.USER_DATA
					);
				const assign = { ...userParams, authToken: token.token };

				this.localStorageService.removeItem(User.USER_DATA);

				this.localStorageService.setDataToStorage(
					User.USER_DATA,
					assign
				);

				return token.token;
			})
		);
	}
}
