import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class TypeImageService {


	public typesDefault = [
		{ id: 0, name: "", url: '/assets/images/type-image/0.jpg' },
	];


	public types = [
		{ id: 1001, name: "Искусство" },
		{ id: 1002, name: "Стендап" },
		{ id: 1003, name: "Танцы" },
		{ id: 1004, name: "Вечеринка" },
		{ id: 1005, name: "Еда" },
		{ id: 1006, name: "Шопинг" },
		{ id: 1007, name: "Семья" },
		{ id: 1008, name: "Дети" },
		{ id: 1009, name: "Йога" },
		{ id: 1011, name: "Кино" },
		{ id: 1012, name: "Ремесло" },
		{ id: 1014, name: "Игра" },
		{ id: 1015, name: "Напитки" },
		{ id: 1016, name: "Бизнес" },
		{ id: 1017, name: "IT" },
		{ id: 1019, name: "Спорт" },
		{ id: 1020, name: "Выставка" },
		{ id: 1021, name: "Концерт" },
		{ id: 1022, name: "Живая музыка" },
		{ id: 1023, name: "Открытый микрофон" },
		{ id: 1024, name: "Медитация" },
		{ id: 1025, name: "Ярмарка" },
		{ id: 1026, name: "Новый год" },
		{ id: 1027, name: "Здоровье" },
		{ id: 1028, name: "Импровизация" },
		{ id: 1029, name: "Музыка" },
		{ id: 1030, name: "Рождество" },
		{ id: 1031, name: "Тренинг" },
		{ id: 1032, name: "Фестиваль" },
		{ id: 1033, name: "Мастер-класс" },
		{ id: 1034, name: "Шоу" },
		{ id: 1035, name: "Духовное" },
		{ id: 1036, name: "Хэллоуин" },
		{ id: 1019, name: "Спорт" },
		{ id: 1009, name: "Йога"},
		{ id: 3, name: "Учёба"},
		{ id: 4, name: "Поездки" },
		{ id: 5, name: "Мастер-класс"},
	];

	public curentTypeImage = new BehaviorSubject<any>([this.typesDefault[0].id]);


}
