import { SessionUser } from 'src/app/utils/session-user.abstract';
import { lastValueFrom } from 'rxjs';
import { inject, Injectable } from "@angular/core";
import { User } from './core/interfaces/enums';
import { LocalStorageService } from "./core/services/localStorage.service";
import { TokenService } from "./core/services/api/user/token.service";
import { PreloaderService } from './core/services/preloader.service';

@Injectable({
	providedIn: 'root'
})

export class AppInitializerService {
	private localStorageService = inject(LocalStorageService);

	private tokenService = inject(TokenService);

	private preloaderService = inject(PreloaderService);

	public async run(): Promise<void> {
		this.preloaderService.setIsLoading(true);
		await this.getUserInfo();
		this.preloaderService.setIsLoading(false);
	}

	private async getUserInfo(): Promise<void> {
		const token = this.localStorageService.getUserToken();

		if (!token) {
			const guestToken = this.localStorageService.getItemLocalStorage(User.GUEST_DATA);

			if (!guestToken) {
				const responseGuestToken = await lastValueFrom(this.tokenService.receive());
				this.localStorageService.setDataToStorage(User.GUEST_DATA, responseGuestToken.token);
			}
			SessionUser.changeIsAuth = false;
			return;
		}

		await this.refreshUserToken();
		SessionUser.changeIsAuth = true;
	}

	private async refreshUserToken(): Promise<void> {
		await lastValueFrom(this.tokenService.refresh());
	}
}
