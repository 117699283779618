import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'transformTags',
})
export class TransformTagsPipe implements PipeTransform {

	transform(id: number, objects: any[]): string {
		
		const result = objects.find(obj => obj.id === id);

		return result ? result.name : '';
	}

}
