import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'getLink',
})
export class GetLinkPipe implements PipeTransform {

	transform(value: string): string {
		const regex = /(https:\/\/[^\s]+)/g;

		if (!regex.test(value)) {
			return value;
		}

		return value.replace(regex, (match) => {
			return `<a href="${match}" target="_blank">${match}</a>`;
		});
	}
}
