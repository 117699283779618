import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GeoCodingService } from './geocoding.service';

@Injectable({
	providedIn: 'root',
})
export class CoordinateService {
	public isTrustGeo = new BehaviorSubject(false);

	public coordinates$ = new BehaviorSubject<number[]>([]);


	public radius = 1500;

	constructor(protected geocodingService: GeoCodingService) {
		geocodingService.coordinates.subscribe({
			next: (value) => {
				this.coordinates$.next(value);
			}
		});
	}

	public init(): void {
		navigator.geolocation.getCurrentPosition(
			this.success.bind(this),
			this.error.bind(this),
			{ enableHighAccuracy: true }
		);
	}

	public searchCoordinateByAddress(address: string): void {
		this.geocodingService.run(address);
	}

	private success({ coords }: { coords: GeolocationCoordinates }): void {
		const { latitude, longitude } = coords;
		this.coordinates$.next([latitude, longitude]);
		this.geocodingService.getAddressIntoCoordinate({lat:latitude,lng:longitude});
		this.isTrustGeo.next(true);
	}

	private error(error: GeolocationPositionError): void {
		console.error('Geolocation error:', error);
		// Если ошибка связана с отказом пользователя в доступе к геолокации, используем координаты Москвы
		this.coordinates$.next([55.7558, 37.6176]);
		this.isTrustGeo.next(false);
	}


}
