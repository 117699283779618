import { Pipe, PipeTransform } from '@angular/core';
import { CoordinateService } from '../../core/services/coordinates/coordinate.service';
import { Events } from '../../core/interfaces/events';

@Pipe({
	name: 'distanceCurrentPosition',
	pure: false
})
export class DistanceCurrentPositionPipe implements PipeTransform {
	private readonly RADIUS: number = 6371;

	public currentPosition: number[] = [];

	public hasGeolocationAccess: boolean = false;

	constructor(private coordinateService: CoordinateService) {
		this.checkGeolocationAccess().then((hasAccess) => {
			this.hasGeolocationAccess = hasAccess;
		});

		this.coordinateService.coordinates$.subscribe((value) => {
			this.currentPosition = value;
		});
	}

	transform(eventCoords: Events.location): string | null {
		if (!this.hasGeolocationAccess) {
			return null;
		}

		if (this.currentPosition.length === 0) {
			return null;
		}

		const distance = this.haversine(this.currentPosition, [eventCoords.lat, eventCoords.lon]);
		return this.formatDistance(distance);
	}

	private async checkGeolocationAccess(): Promise<boolean> {
		return new Promise((resolve) => {
			if (!navigator.geolocation) {
				resolve(false);
				return;
			}

			navigator.geolocation.getCurrentPosition(
				() => resolve(true),
				(error) => {
					if (error.code === error.PERMISSION_DENIED) {
						resolve(false);
					} else {
						resolve(false);
					}
				}
			);
		});
	}

	private haversine(coord1: number[], coord2: number[]): number | string {
		const lat1 = this.toRadians(coord1[0]);
		const lon1 = this.toRadians(coord1[1]);
		const lat2 = this.toRadians(coord2[0]);
		const lon2 = this.toRadians(coord2[1]);
		const dlat = lat2 - lat1;
		const dlon = lon2 - lon1;

		const a =
			Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
		const c = 2 * Math.asin(Math.sqrt(a));

		const resultDistance = Math.round(this.RADIUS * c);

		if (resultDistance <= 1) {
			return 'меньше 1 км';
		}

		return resultDistance;
	}

	private formatDistance(distance: number | string): string {
		if (typeof distance === 'number') {
			return `${distance} км`;
		}
		return distance;
	}

	private toRadians(degrees: number): number {
		return degrees * (Math.PI / 180);
	}
}
