import { signal } from '@angular/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { IWsMessage } from '../../core/interfaces/websocket/websocket.interfaces';
import { CoordinateService } from '../../core/services/coordinates/coordinate.service';
import { WebsocketService } from '../../core/services/websocket/websocket.service';
import { Events, EventTypeSearchMain } from '../../core/interfaces/events';
import { MapHeaderService } from '../components/map/map-header/map-header.service';

export class EventsMainSubClass {
	public clockAndData = {
		year: '',
		month: '',
		day: '',
		hr: '',
		min: '',
		sec: '',
	};

	public unixDate!: moment.Moment;

	public timeSelectNow!: string;

	public valTime = {
		hour: '',
		minute: '',
	};

	public dateNow = new Date();

	public position!: number[];

	public destroy$ = new Subject<void>();

	public request = signal(true);

	public page = 0;

	public typeSearch!: EventTypeSearchMain;

	public today = moment(this.dateNow).format();

	public timeNow = moment().format('HH:mm');

	public clearPage$ = new Subject<void>();

	constructor(
		protected wsService: WebsocketService,
		protected coordinateService: CoordinateService,
		private headerService: MapHeaderService
	) {
	}

	public getEvents(
		type: EventTypeSearchMain,
		date: moment.Moment,
		time: number,
		page?: number,
		types?: number[]
	): void {
		this.request.set(true);
		this.page = page ?? 0;

		const [lat, lon] = this.coordinateService.coordinates$.value;

		const unixDate = date.unix();

		this.sendEventRequest(
			{
				date: unixDate,
				time,
				coordinateLat: lat,
				coordinateIon: lon,
				types,
			},
			type,
		);

		this.request.set(false);
	}


	private sendEventRequest(
		params: { date: number; time: number; coordinateLat: number; coordinateIon: number, types?: number[] },
		type: EventTypeSearchMain
	) {
		const { date, time, coordinateLat, coordinateIon, types } = params;
		this.typeSearch = type;

		if (type === 'recommendation') {
			this.wsService.send<IWsMessage<Events.Search>>({
				action: 'general_events',
				body: {
					min: time,
					time_start: date,
					lat: coordinateLat,
					lon: coordinateIon,
					radius: 0, // If there is no radius, this indicates recommendations
					page: this.page,
				}
			});
			return;
		}
		this.wsService.send<IWsMessage<Events.Search>>({
			action: 'general_events',
			body: {
				min: time,
				time_start: date,
				lat: coordinateLat,
				lon: coordinateIon,
				radius: Number(this.headerService.radiusSubject.value),
				page: this.page,
				...(types && types.length > 0 && { types })
			}
		});
	}
}
