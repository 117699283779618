import { Injectable, OnDestroy } from '@angular/core';
import {
	Observable,
	Subject,
	map,
	BehaviorSubject,
	firstValueFrom,
	lastValueFrom,
} from 'rxjs';
import {
	webSocket,
	WebSocketSubject,
	WebSocketSubjectConfig,
} from 'rxjs/webSocket';
import { ENV } from '@app/env';
import { Actions,
	 ILoginWebsocketBody,
	 IWebsocketService,
	 IWsMessage
} from '../../interfaces/websocket/websocket.interfaces';
import { LocalStorageService } from '../localStorage.service';
import { TokenService } from '../api/user/token.service';
import { User } from '../../interfaces/enums';
import { IUserStorageData } from '../../interfaces/users';


@Injectable()
export class WebsocketService implements IWebsocketService, OnDestroy {
	private wsMessages$!: Subject<MessageEvent>;

	private wsMsQueue: any = null;

	public isLoading = new BehaviorSubject<boolean>(false);

	private webSocket$!: WebSocketSubject<any>;

	private config!: WebSocketSubjectConfig<any>;

	public isConnected = new BehaviorSubject<boolean>(false);

	public isAuthorized = false;

	private reconnectAttempts = 3;

	public status!: Observable<boolean>;

	private lastAction?: Actions;

	constructor(private localStorageService: LocalStorageService, private tokenService: TokenService) {}

	public async connect(action?: Actions, isExpired?: boolean): Promise<void> {
		this.wsMessages$ = new Subject();
		let token: string | undefined;

		let guestData: {
			success: boolean
			user: number
			token: string | undefined;
		};

		const userData = await this.updateToken(isExpired);

		if (!userData?.authToken) {
			guestData = await firstValueFrom(this.tokenService.receive());
			token = guestData.token;

		} else {
			token = userData?.authToken;
		}

		this.config = {
			url: userData?.id ? `${ENV.ws}?user=${userData.id}&type=u` : `${ENV.ws}?user=${guestData!.user}&type=g`,
			closeObserver: {
				next: () => {
					console.log('WS Disconnected');
					this.isConnected.next(false);
				},
			},
			openObserver: {
				next: () => {
					console.log('WS Connected');
					this.isConnected.next(true);

					this.send<IWsMessage<ILoginWebsocketBody>>({
						action: 'login',
						body: {
							action: action || 'general_events',
							token: token!
						}
					});

					if (this.wsMsQueue !== null) {
						this.webSocket$.next(this.wsMsQueue);
						this.wsMsQueue = null;
					}
				},
			},
			deserializer: (event: Event) => event,
		};
		this.webSocket$ = webSocket(this.config);

		this.webSocket$.subscribe({
			next: (message) => {
				this.wsMessages$.next(message);
			}
		});
	}

	public on<T>(): Observable<T> {
		return this.wsMessages$.pipe(
			map((mes) => {
				try {
					let status = JSON.parse(mes.data).status
					if (status == 'auth') {
						this.disconnect();
						if (this.lastAction) {
							this.connect(this.lastAction, true);
						} else {
							this.connect(undefined, true);
						}
					}
					return JSON.parse(mes.data);
				} catch (error) {
					console.error('Error parse message', error);
					return mes.data;
				}
			})
		);
	}

	public send<T>(body: T): void {
		if (!this.isConnected.value) {
			this.wsMsQueue = body;
			return;
		}
		this.webSocket$.next(body);
	}

	public async updateToken(isExpired?: boolean): Promise<IUserStorageData | undefined> {
		if (isExpired) {
			await lastValueFrom(this.tokenService.refresh());
			return this.localStorageService.getItemLocalStorage<IUserStorageData>(User.USER_DATA);
		}
		return this.localStorageService.getItemLocalStorage<IUserStorageData>(User.USER_DATA);
	}

	public disconnect(): void {
		if (this.webSocket$) {
			this.webSocket$.complete();
			this.isConnected.next(false);
		}
	}

	public ngOnDestroy(): void {
		this.disconnect();
	}
}
