import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { Router } from '@angular/router';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SessionUser } from 'src/app/utils/session-user.abstract';
import { PasswordModule } from 'primeng/password';
import { LocalStorageService } from '../../../core/services/localStorage.service';
import { AuthApiService } from '../../../core/services/api/user/auth/auth-api.service';
import type { AuthUserData } from '../../../core/interfaces/auth-user';
import { UserData } from '../../../core/interfaces/localStorage';
import { User } from '../../../core/interfaces/enums';

@Component({
	selector: 'app-sign-in',
	standalone: true,
	imports: [ButtonModule, CommonModule, PasswordModule, ReactiveFormsModule],
	templateUrl: './signIn.component.html',
	styleUrls: ['./signIn.component.scss'],
})
export class SignInComponent {
	@Output() submitSignIn = new EventEmitter<AuthUserData>();

	public validFormData = false;

	public sigInForm = new FormGroup({
		login: new FormControl<string>('', {
			nonNullable: true,
			validators: [Validators.required, Validators.pattern(/^@.*$/)],
		}),
		password: new FormControl<string>('', {
			nonNullable: true,
			validators: [Validators.required],
		}),
	});

	constructor(
		private authApiService: AuthApiService,
		protected localStorageService: LocalStorageService,
		protected router: Router
	) {}

	public submitForm(): void {
		if (!this.sigInForm.valid) {
			return;
		}
		const { login, password } = this.sigInForm.controls;
		this.requestSignIn({
			login: login.value,
			password: password.value,
		});
	}

	public requestSignIn(userData: AuthUserData): void {
		this.authApiService.signIn(userData).subscribe({
			next: (result) => {
				this.localStorageService.setToken(result.token);
				this.localStorageService.setDataToStorage<UserData>(
					User.USER_DATA,
					{
						id: result.user,
						authToken: result.token,
					}
				);
				this.localStorageService.removeItem(User.GUEST_DATA);
				SessionUser.changeIsAuth = true;
				this.router.navigateByUrl('profile');
			},
		});
	}
}
